.sw-bg-std-white {
  background-color: #ffffff;
}

.sw-bg-std-grey {
  background-color: #f5f5f5;
}

.sw-bg-std-blue {
  background-color: #075c8e;
}

// .sw-bg-cover-img {
//   background-image: url(/images/background.jpg);
//   background-attachment: fixed;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// }

// .sw-bg-cover-img-alt {
//   background-image: url(/images/background.jpg);
//   background-size: cover;
//   background-position: center;

//   @media only screen and (max-width: 767px) {
//     height: 8vh;
//   }

//   @media only screen and (min-width: 768px) {
//     height: 12vh;
//   }
// }
