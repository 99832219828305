@import "./variables.scss";

.error-text {
  color: $swErrorColor;
}

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.content-section {
  h1 {
    font-family: $headingFontFamily;
    color: $swTertiaryColor;
    font-weight: 400;
    letter-spacing: 2px;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  }

  h2 {
    font-family: $headingFontFamily;
    color: $headingFontColor;
    font-weight: 300;
    letter-spacing: 2px;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  }

  h3 {
    font-family: $headingFontFamily;
    color: $headingFontColor;
    letter-spacing: 3px;
    margin: 40px 0 40px 0;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  }

  h4,
  h5,
  h6 {
    font-family: $headingFontFamily;
    color: $headingFontColor;
    letter-spacing: 3px;
  }

  p {
    color: $paragraphColor;
  }

  li {
    color: $paragraphColor;
  }

  ul.two-col {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    @media only screen and (max-width: 500px) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }
  }

  label {
    color: $labelColor;
    font-weight: 500 !important;
    letter-spacing: 0.5px;
  }

  input[type="text"],
  textarea,
  #react-select-2-input {
    background-color: #f5f5f7 !important;
  }

  .icon-container {


    i {
      color: #f5f5f5 !important;
      font-size: 45px;
    }
  }
}
