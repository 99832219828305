@import "assets/styles/variables.scss";

.image-gallery {
  .owl-stage-outer {
    padding: 10px;
  }

  .owl-carousel .owl-stage {
    display: flex;
  }

  .owl-dots .owl-dot span {
    background-color: $swSecondaryColor !important;
  }
  .owl-dots .active span {
    background-color: $swPrimaryColor !important;
  }

  .owl-item {
    height: 100px;
  }

  // .home-partner {
  //   height: 95px;
  //   background-position: center center;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  // }
}
