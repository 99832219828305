@import "./variables.scss";

@mixin buttonStyle(
  $color,
  $font-weight,
  $bg-color,
  $hover-bg-color,
  $padding,
  $border,
  $hover-color,
  $hover-border
) {
  color: $color !important;
  font-weight: 600;
  background-color: $bg-color !important;
  padding: $padding !important;
  border: $border;
  transition: 0.2s all;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: $hover-bg-color !important;
    color: $hover-color !important;
    border: $hover-border !important;
  }
}

.mt-transparent-button {
  @include buttonStyle(
    #fff,
    unset,
    unset,
    #fff,
    10px 20px 10px 20px,
    1px solid #fff,
    black,
    1px solid white
  );
}

.sw-std-btn,
.sw-btn-primary {
  @include buttonStyle(
    #ffffff,
    500,
    $swBtnPrimaryColor,
    $swBtnPrimaryHoverColor,
    10px 100px,
    unset,
    #fff,
    unset
  );
}

.mt-btn-button {
  @include buttonStyle(
    #ffffff,
    unset,
    $swBtnSecondaryColor,
    $swBtnSecondaryHoverColor,
    10px 20px 10px 20px,
    unset,
    #fff,
    unset
  );
}

.mt-blue-mini-button {
  @include buttonStyle(
    #ffffff,
    unset,
    $swBtnPrimaryColor,
    $swBtnPrimaryHoverColor,
    5px 10px,
    unset,
    #fff,
    unset
  );
}

.mt-aqua-mini-button {
  @include buttonStyle(
    #ffffff,
    unset,
    $swBtnSecondaryColor,
    $swBtnSecondaryHoverColor,
    5px 10px,
    unset,
    #fff,
    unset
  );
}

.sw-link {
  cursor: pointer !important;
  text-decoration: none;
  border: none !important;
  color: $swSecondaryColor;
}
