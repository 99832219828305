footer {
  background: #585858;
  margin: 0;
  color: #fff;
  position: relative;

  .container {
    max-width: 1140px;

    h5 {
      font-weight: 700col;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      color: #212529;
    }

    .nav-item-style {
      text-decoration: none;
      letter-spacing: 1.1px;
      font-size: 16px;
      color: #fff;
      transition: 1s all;

      &:hover {
        color: #757273;
      }
    }

    .nav-item-link {
      @extend .nav-item-style;
      font-weight: bold;
      text-transform: uppercase;
    }

    .email-link {
      a {
        text-transform: none !important;
      }
    }

    .buttons {
      button {
        min-width: 140px;
      }

      @media (max-width: 767.98px) {
        margin-top: 30px;
      }

      .quote {
        @media (max-width: 767.98px) {
          margin-right: 10px;
        }
      }
    }

    .fas {
      width: 16px;
      text-align: center;
    }
  }

  .footnote {
    position: absolute;
    bottom: 0 !important;
    // background-color: black;
  }
}
