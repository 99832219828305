.gallery {
  .img-itm {
    cursor: pointer;

    .img {
      height: 200px;
      object-fit: cover;
    }
  }
}
