.custom-styles {
  @import "./assets/styles/variables.scss";
  @import "./assets/styles/backgrounds.scss";
  @import "./assets/styles/button-styles.scss";
  @import "./assets/styles/box-shadows.scss";
  @import "./assets/styles/text-styles.scss";
  @import "./assets/styles/forms.scss";
  @import "./assets/styles/responsive-table.scss";
  @import "./assets/styles/client.scss";
  @import "./assets/styles/services-default.scss";
}

@import "./assets/styles/dialog-buttons.scss";
@import "./assets/styles/other-texts.scss";
