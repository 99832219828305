.full-layout {
  // TODO: confirm if we need this
  position: relative;

  @media only screen and (max-width: 767px) {
    $value: 67px;
    top: $value;
    margin-bottom: $value;
  }

  @media only screen and (min-width: 768px) {
    $value: 156px;
    top: $value;
    margin-bottom: $value;
  }
}
