$headingFontColor: #17375e;
$headingFontFamily: "Raleway", sans-serif;
$defaultFontFamily: "Raleway", sans-serif;
$paragraphColor: #333333;
$labelColor: #b2b4b2;

$swPrimaryColor: #075c8e;
$swSecondaryColor: #979797;
$swTertiaryColor: grey;

$swBtnPrimaryColor: #075c8e;
$swBtnPrimaryHoverColor: #979797;
$swBtnSecondaryColor: #979797;
$swBtnSecondaryHoverColor: #797979;

$swAltColor: #179ea3;
$swErrorColor: #ad0c0c;

$navBarBgColor: #fff;
$navBarItemBgColor: #363948;
$navBarItemColor: #363948;
$navBarItemHoverColor: #fff;
$navBarDropDownMenuBgColor: #075c8e;
