@import "./variables.scss";

/*
* Client Specific Sass file
* Adjust colors based on client's theme
* Solvable 8.30.2019 -rcnet
*/

/* Start Client Nav Bar Colors */
.navbar-bg {
  background-color: $navBarBgColor; // Nav Bar color
}
.navbar-default {
  .navbar-nav > li > a,
  .navbar-nav > li > a:focus {
    color: $navBarItemColor;
  }

  .navbar-nav > li {
    text-transform: uppercase;
    margin-top: 10px;
    font-weight: 600;
  }

  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus,
  .navbar-nav > li > a:hover,
  .navbar-nav > dropdown-menu-right:hover,
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:focus,
  .navbar-nav > .open > a:hover {
    background-color: $swPrimaryColor; // Hover item color
    border-radius: 3px;
  }
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus,
  .navbar-nav > li > a:hover {
    color: $navBarItemHoverColor !important;
  }

  .navbar-toggler {
    border: none;
    color: $navBarItemColor;
  }

  .dropdown-menu {
    background-color: $navBarDropDownMenuBgColor; // Drop down menu color

    a {
      color: #fff !important;
    }
  }
}

/* End Client Nav Bar Colors */
